import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import {
  Navbar,
  NavbarToggler,
  UncontrolledCollapse,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import styles from "../header/header.module.css";
import { FaCaretDown } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import prLogoLight from "../../assets/progress-logo-white.svg";

export default () => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        craft {
          navItems: entries(
            siteId: "4"
            orderBy: "menuOrder"
            status: "enabled"
          ) {
            id
            title
            slug
            level
            children {
              id
              title
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <header>
        <Helmet
          title="Progress Mfg. Brand Protection Policy"
          meta={[
            {
              name: "description",
              content:
                "Progress Mfg. Inc., a leading manufacturer and supplier of hitches and trailer products, has adopted a unilateral Minimum Advertised Price Policy (MAP Policy)which applies to all authorized Progress Mfg. resellers in the United States of America."
            },
            {
              name: "keywords",
              content:
                "Progress Mfg., Progress Manufacturing, MAP Policy, Brand Protection Policy"
            }
          ]}
        />
        <Navbar light expand="md" fixed="top" className={styles.mainMenuNavBar}>
          <NavbarToggler className={styles.hamburgerMenu} id="toggler" />
          <NavbarBrand className={styles.mainMenuLogo} href="/">
            <img src={prLogoLight} alt="Progress Manufacturing Logo" />
          </NavbarBrand>
          <UncontrolledCollapse navbar toggler="#toggler">
            <Nav navbar className={styles.mainMenuList}>
              {data.craft.navItems.map((navItem, index) => {
                if (navItem.level === 1) {
                  return (
                    <div key={index}>
                      <NavItem className={styles.subMenuItem}>
                        <NavLink
                          className={styles.mainMenuLinkWithDropdown}
                          href={`/${navItem.slug}`}
                        >
                          {navItem.title}
                          <FaCaretDown className={styles.caret} />
                        </NavLink>
                        <ul className={styles.subMenuDropDown}>
                          {navItem.children.map((childNavItem, childIndex) => (
                            <li key={childIndex}>
                              <a
                                className={styles.mainMenuSubLink}
                                href={`/${childNavItem.slug}`}
                              >
                                {childNavItem.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </NavItem>
                    </div>
                  );
                } else if (navItem.level !== 2 && navItem.level === null) {
                  if (navItem.title !== "404 Error") {
                    return (
                      <NavItem key={index} className={styles.singleNavItem}>
                        <NavLink
                          className={styles.mainMenuLink}
                          href={`/${navItem.slug}`}
                        >
                          {navItem.title}
                        </NavLink>
                      </NavItem>
                    );
                  }
                }
              })}
            </Nav>
          </UncontrolledCollapse>
        </Navbar>
      </header>
    )}
  />
);
