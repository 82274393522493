import React from "react"
import { Container, Row, Col } from "reactstrap"
import styles from "../footer/footer.module.css"
import eqLogo from "../../assets/equalizer-logo.svg"
import fwLogo from "../../assets/fastway-logo.svg"
import prLogoDark from "../../assets/progress-logo-black.png"
import fbLogo from "../../assets/facebook.svg"
import ytLogo from "../../assets/youtube.svg"
import twLogo from "../../assets/twitter.svg"
import igLogo from "../../assets/instagram.svg"
import gpLogo from "../../assets/google-plus.svg"


export default () => (
    <footer className={styles.siteFooter}>
        <Container>
            <Row>
                <Col sm={{ size: 12, order: 2 }} md="4">
                    <div className={styles.siteFooterLinks}><a href="https://www.progressmfg.com" target="_blank" rel="noopener noreferrer"><img src={ prLogoDark } alt="Progress Manufacturing Logo"/></a></div>
                </Col>
                <Col sm={{ size: 12, order: 1 }} md="4">
                <div className={styles.siteFooterLinks}>
                    <a className={styles.siteFooterLogo} href="https://www.equalizerhitch.com" target="_blank" rel="noopener noreferrer"><img src={ eqLogo } alt="Equal-i-zer Logo" /></a>
                    <ul className={styles.siteFooterSocialList}>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/equalizerhitch"><img src={ fbLogo } alt="Equalizer Facebook Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/equalizerhitch/"><img src={ igLogo } alt="Equalizer Instagram Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/EqualizerHitch"><img src={ ytLogo } alt="Equalizer Youtube Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/equalizerhitch"><img src={ twLogo } alt="Equalizer Twitter Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://plus.google.com/+Equalizerhitch"><img src={ gpLogo } alt="Equalizer Google Plus Logo"/></a></li>
                    </ul>
                </div>
                </Col>
                <Col sm={{ size: 12, order: 3 }} md="4">
                <div className={styles.siteFooterLinks}>
                    <a className={styles.siteFooterLogo} href="https://www.fastwaytrailer.com" target="_blank" rel="noopener noreferrer"><img src={ fwLogo } alt="Fastway Trailer Products Logo"/></a>
                    <ul className={styles.siteFooterSocialList}>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/fastwaytrailer"><img src={ fbLogo } alt="Fastway Facebook Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/fastwaytrailer/"><img src={ igLogo } alt="Fastway Instagram Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/FastwayTrailer"><img src={ ytLogo } alt="Fastway Youtube Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/fastwaytrailer"><img src={ twLogo } alt="Fastway Twitter Logo"/></a></li>
                        <li className={styles.siteFooterSocialLinks}><a target="_blank" rel="noopener noreferrer" href="https://plus.google.com/+Fastwaytrailerproducts"><img src={ gpLogo } alt="Fastway Google Plus Logo"/></a></li>
                    </ul>
                </div>
                </Col>
            </Row>
        </Container>
    </footer>
)